import React, { Component } from 'react'
import isEmail from 'validator/lib/isEmail'

const EMAIL_ERROR_MESSAGE = 'Bitte geben Sie eine gültige EMail-Adresse an'
const LAST_NAME_ERROR_MESSAGE = 'Bitte geben Sie Ihren Nachnamen an'
const DUPLICATED_EMAIL_ERROR_MESSAGE =
  'Diese E-Mail-Adresse existiert bereits für diese Region'

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emailAddress: this.props.emailAddress || '',
      salutation: this.props.salutation || 'MALE',
      lastName: this.props.lastName || '',
      id: this.props.id,
      errors: {
        lastName: false,
        emailAddress: false,
        duplicatedEmailAddress: false
      }
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.validate = this.validate.bind(this)
    this.onEnterHandler = this.onEnterHandler.bind(this)
  }

  componentDidMount() {
    this.setState({
      emailAddress: this.props.emailAddress || '',
      salutation: this.props.salutation || 'MALE',
      lastName: this.props.lastName || '',
      id: this.props.id,
      errors: {
        lastName: false,
        emailAddress: false,
        duplicatedEmailAddress: false
      }
    })
  }

  validate() {
    const { emailAddress, lastName, id } = this.state
    const isAnEmail = isEmail(emailAddress)

    let errors = {}
    errors.emailAddress = !isAnEmail
    errors.lastName = lastName.trim() === ''
    errors.duplicatedEmailAddress = this.props.allContactsInLeadEntitlement.some(
      contact =>
        contact.id !== id &&
        contact.emailAddress.toLowerCase() === emailAddress.toLowerCase()
    )

    this.setState({
      ...this.state,
      errors
    })
    return (
      !errors.emailAddress && !errors.lastName && !errors.duplicatedEmailAddress
    )
  }

  onSubmit() {
    this.validate() &&
      this.props.onSubmit(
        {
          id: this.props.id,
          salutation: this.state.salutation,
          lastName: this.state.lastName,
          emailAddress: this.state.emailAddress
        },
        this.props.leadEntitlementId
      )
  }

  onChangeHandler(event) {
    const name = event.target.name

    this.setState({
      ...this.state,
      [name]: event.target.value
    })
  }

  onEnterHandler(event) {
    event.keyCode === 13 && this.onSubmit()
  }

  render() {
    const { emailAddress, salutation, lastName } = this.state
    const { onCancel, adding } = this.props
    return (
      <div className="padding-bottom-s contact-form">
        <div className="grid grid-flex gutter-m grid-fill-rows">
          <div className="grid-item palm-one-whole grid-item-fixed-width">
            <div className="select-container">
              <div className="select-input-wrapper">
                <select
                  className="select"
                  defaultValue={salutation === 'MALE' ? 'MALE' : 'FEMALE'}
                  name="salutation"
                  onChange={this.onChangeHandler}
                >
                  <option value={'MALE'}>Herr</option>
                  <option value={'FEMALE'}>Frau</option>
                </select>
              </div>
            </div>
          </div>
          <div className="grid-item palm-one-whole">
            <div className="input-text-container">
              <input
                type="text"
                name="lastName"
                maxLength="100"
                placeholder="Nachname"
                value={lastName}
                onChange={this.onChangeHandler}
                onKeyDown={this.onEnterHandler}
                className={`input-text ${this.state.errors.lastName &&
                  'error'}`}
              />
            </div>
          </div>
          <div className="grid-item palm-one-whole">
            <div className="input-text-container">
              <input
                type="text"
                name="emailAddress"
                maxLength="100"
                placeholder="E-Mail-Adresse"
                value={emailAddress}
                onChange={this.onChangeHandler}
                onKeyDown={this.onEnterHandler}
                className={`input-text ${this.state.errors.emailAddress &&
                  'error'}`}
              />
            </div>
          </div>
          <div className="grid-item one-whole align-right">
            <input
              id="submitFormButton"
              onClick={this.onSubmit}
              type="submit"
              value={adding ? 'Hinzufügen' : 'Speichern'}
              className="button-primary font-normal palm-one-whole"
            />
            <a
              onClick={onCancel}
              className="button palm-one-whole cancel-button"
            >
              Abbrechen
            </a>
          </div>
        </div>
        <div>
          {this.state.errors.emailAddress && (
            <div className="font-error font-xs">{EMAIL_ERROR_MESSAGE}</div>
          )}
          {this.state.errors.lastName && (
            <div className="font-error font-xs">{LAST_NAME_ERROR_MESSAGE}</div>
          )}
          {this.state.errors.duplicatedEmailAddress && (
            <div
              data-e2e="duplicatedEmailErrorMessage"
              className="font-error font-xs"
            >
              {DUPLICATED_EMAIL_ERROR_MESSAGE}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ContactForm
