import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getSorterLeadEntitlements,
  loadLeadEntitlements,
} from '../reducers/leadEntitlements'
import { setOrderKey } from '../reducers/ui'
import { cleanErrors } from '../reducers/errors'
import './Home.css'
import LeadEntitlement from '../components/LeadEntitlement'
import SortingCaret from '../components/SortingCaret'
import ErrorModal from '../components/ErrorModal'

class Home extends Component {
  constructor(props) {
    super(props)
    this.orderByHeader = this.orderByHeader.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.props.isLoggedIn && this.props.loadLeadEntitlements()
  }

  orderByHeader(nextSortingKey) {
    const {
      sorting: { key, direction },
      setOrderKey,
    } = this.props
    if (nextSortingKey === key) {
      setOrderKey(nextSortingKey, direction === 'asc' ? 'desc' : 'asc')
    } else {
      setOrderKey(nextSortingKey)
    }
  }

  render() {
    const {
      leadEntitlements,
      isLoadingLeadEntitlements,
      errors,
      isThereAnError,
      cleanErrors,
      isLoggedIn,
      sorting,
    } = this.props

    if (!isLoggedIn) {
      return (
        <div className="font-center horizontal-center margin-l">
          <i
            className="fa fa-spinner fa-pulse fa-2x fa-fw"
            aria-hidden="true"
          />
          <br />
          <span className="sr-only">Loading...</span>
        </div>
      )
    } else {
      return (
        <div>
          <h3>MaklerSuchmaschine E-Mail-Einstellungen</h3>
          <p className="margin-bottom-xl">
            Hinterlegen Sie hier für Ihre gebuchte(n) Region(en) die
            E-Mail-Adressen der Empfänger, an die eine Anfrage aus der
            MaklerSuchmaschine gesendet werden soll. Sofern keine E-Mail-Adresse
            hinterlegt ist, wird eine E-Mail an die im Branchenbuch hinterlegte
            Kontaktadresse verschickt.
          </p>

          <h4>E-Mail Einstellungen</h4>

          {!isLoadingLeadEntitlements && (
            <div className="grid grid-flex gutter-s home-settings-buttons grid-fill-rows">
              <div className="grid-item palm-one-half">
                <button
                  className={`${
                    sorting.key === 'regionName' ? 'button-secondary' : 'button'
                  } one-whole`}
                  onClick={() => this.orderByHeader('regionName')}
                >
                  <span className="margin-right-xs">Gebiet / Region</span>
                  <SortingCaret sortingKey="regionName" sorting={sorting} />
                </button>
              </div>
              <div className="grid-item palm-one-half">
                <button
                  className={`${
                    sorting.key === 'type' ? 'button-secondary' : 'button'
                  } one-whole`}
                  onClick={() => this.orderByHeader('type')}
                >
                  <span className="margin-right-xs">Vertragsart</span>
                  <SortingCaret sortingKey="type" sorting={sorting} />
                </button>
              </div>
              <div className="grid-item palm-one-half">
                <button
                  className={`${
                    sorting.key === 'startDate' ? 'button-secondary' : 'button'
                  } one-whole`}
                  onClick={() => this.orderByHeader('startDate')}
                >
                  <span className="margin-right-xs">Startdatum</span>
                  <SortingCaret sortingKey="startDate" sorting={sorting} />
                </button>
              </div>
              <div className="grid-item palm-one-half">
                <button
                  className={`${
                    sorting.key === 'endDate' ? 'button-secondary' : 'button'
                  } one-whole`}
                  onClick={() => this.orderByHeader('endDate')}
                >
                  <span className="margin-right-xs">Enddatum</span>
                  <SortingCaret sortingKey="endDate" sorting={sorting} />
                </button>
              </div>
            </div>
          )}

          <div className="grid grid-flex gutter-xl margin-top-xl">
            {isLoadingLeadEntitlements && (
              <div className="grid-item one-whole">
                <div className="font-center horizontal-center margin-l">
                  <i
                    className="fa fa-spinner fa-pulse fa-2x fa-fw"
                    aria-hidden="true"
                  />
                  <br />
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {leadEntitlements &&
              leadEntitlements.map((leadEntitlement, index) => (
                <LeadEntitlement
                  key={index}
                  error={errors}
                  leadEntitlement={leadEntitlement}
                />
              ))}
          </div>
          {isThereAnError && (
            <ErrorModal onClose={cleanErrors} errors={errors} />
          )}
        </div>
      )
    }
  }
}

Home.propTypes = {
  leadEntitlements: PropTypes.array,
  isLoadingLeadEntitlements: PropTypes.bool,
  loadLeadEntitlements: PropTypes.func,
  cleanErrors: PropTypes.func,
  setOrderKey: PropTypes.func,
  error: PropTypes.object,
  isThereAnError: PropTypes.bool,
  isLoggedIn: PropTypes.bool.isRequired,
  sorting: PropTypes.object,
}

const mapStateToProps = (state) => ({
  isLoadingLeadEntitlements: state.leadEntitlements.isLoadingLeadEntitlements,
  leadEntitlements: getSorterLeadEntitlements(state),
  sorting: state.ui.sorting,
  errors: state.errors,
  isThereAnError: !!(state.errors.leadEntitlements || state.errors.contact),
  isLoggedIn: state.auth.isLoggedIn,
})

export default connect(mapStateToProps, {
  loadLeadEntitlements,
  setOrderKey,
  cleanErrors,
})(Home)
