import React from 'react'
import { Provider } from 'react-redux'
import querystring from 'query-string'
import thunkMiddleware from 'redux-thunk'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { configureStore } from '@reduxjs/toolkit'
import { autoRehydrate } from 'redux-persist'
import { createRoot } from 'react-dom/client'
import { authMiddleware, authorize, loginSuccess } from './oauth/lib'
import reducer from './reducers/reducer'
import { isDevelopmentMode } from './config'
import reCaptcha from './google/recaptcha'
import App from './App'

const store = configureStore({
  reducer,
  middleware: [thunkMiddleware, authMiddleware],
  devTools: isDevelopmentMode(),
  enhancers: [autoRehydrate()],
})

const response = querystring.parse(window.location.hash.slice(1))

if (isDevelopmentMode()) {
  console.log('Running in development mode!')
  response.access_token = 'LOCAL_ACCESS_TOKEN'
}

store.dispatch(response.access_token ? loginSuccess(response.access_token) : authorize())

reCaptcha.load()

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
])

const rootElement = document.getElementById('root') ?? document.createElement('div')
createRoot(rootElement).render(
  <div>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </div>
)
