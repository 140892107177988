import querystring from 'query-string'
import cuid from 'cuid'
import config from './../config'

export const authorizeMe = () => {
  const state = cuid()
  const query = querystring.stringify({
    state,
    response_type: 'token',
    client_id: config.oauth.client,
    scope: config.oauth.scope,
    redirect_uri: config.oauth.redirect
  })
  window.location = config.oauth.url + '?' + query
}

export default authorizeMe
