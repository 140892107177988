import config from '../config'

function load() {
  if (window['grecaptcha']) {
    proceed(config.reCaptchaSiteKey)
    return
  }

  const reCaptchaScript = document.createElement('script')
  reCaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${config.reCaptchaSiteKey}`
  reCaptchaScript.addEventListener('load', () =>
    proceed(config.reCaptchaSiteKey)
  )
  const head = document.getElementsByTagName('head')[0]
  head.appendChild(reCaptchaScript)
}

function proceed(apiKey) {
  window['grecaptcha'].ready(function () {
    window['grecaptcha'].execute(apiKey, { action: 'scoutManagerHomepage' })
  })
}

export default { load, proceed }
