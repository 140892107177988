export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'
export const AUTHORIZE = 'AUTHORIZE'

export const loginSuccess = token => ({
  type: LOGIN_SUCCESS,
  token
})

export const logout = () => ({
  type: LOGOUT
})

export const authorize = () => ({
  type: AUTHORIZE
})
