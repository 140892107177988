import { setToken, removeToken } from './util/token'
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, AUTHORIZE } from './actions'
import { authorizeMe } from './oauth2'

const authMiddleware = () => next => action => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      setToken(action.token)
      break
    case LOGIN_FAILURE:
    case LOGOUT:
      removeToken()
      window.localStorage.clear()
      break
    case AUTHORIZE:
      authorizeMe()
      break
    default:
      break
  }

  return next(action)
}

export default authMiddleware
