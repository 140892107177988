import development from './profiles/development'
import pro from './profiles/pro'
import tuv from './profiles/tuv'
import test from './profiles/tests'

const config = {
  development,
  test,
  pro,
  tuv
}

export const isDevelopmentMode = () =>
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_PROFILE === 'development'

export default config[process.env.NODE_ENV] ||
  config[process.env.REACT_APP_PROFILE]
