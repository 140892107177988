const initialState = {
  contact: null,
  leadEntitlements: false,
}

export const errors = (state = initialState, action) => {
  switch (action.type) {
    case 'LEAD_ENTITLEMENTS_FETCH_ERROR':
      return {
        ...state,
        leadEntitlements: true,
      }
    case 'CONTACT_OPERATION_ERROR':
      return {
        ...state,
        contact: { id: action.contactId, method: action.method },
      }
    case 'CLEAN_ERRORS':
      return {
        ...initialState,
      }
    default:
      return { ...state }
  }
}

export const errorOccurredDuringLeadEntitlementFetch = (err) => {
  console.log('LEAD_ENTITLEMENTS_FETCH_ERROR', err)
  return {
    type: 'LEAD_ENTITLEMENTS_FETCH_ERROR',
  }
}

export const errorOccurredDuringDeleteContact = (contactId) => ({
  type: 'CONTACT_OPERATION_ERROR',
  contactId: contactId,
  method: 'DELETE',
})

export const errorOccurredDuringAddContact = () => ({
  type: 'CONTACT_OPERATION_ERROR',
  contactId: null,
  method: 'POST',
})

export const errorOccurredDuringUpdateContact = (contactId) => ({
  type: 'CONTACT_OPERATION_ERROR',
  contactId: contactId,
  method: 'POST',
})

export const cleanErrors = () => ({
  type: 'CLEAN_ERRORS',
})
