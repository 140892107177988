import some from 'lodash/some'

const initialState = {
  addingToLeadEntitlement: -1,
  editingContact: -1,
  unfoldedLeadEntitlements: [],
  sorting: {
    key: 'regionName',
    direction: 'asc'
  }
}

export const ui = (state = initialState, action) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return {
        ...state,
        ...action.payload.ui
      }
    case 'EDITING_CONTACT':
      return {
        ...state,
        editingContact: action.contactId
      }
    case 'ADDING_CONTACT_TO_LEAD_ENTITLEMENT':
      return {
        ...state,
        addingToLeadEntitlement: action.leadEntitlementId
      }
    case 'SORTING_ORDER_CHANGE': {
      return {
        ...state,
        sorting: { ...action.sorting }
      }
    }
    case 'UNFOLD_LEAD_ENTITLEMENT': {
      return {
        ...state,
        unfoldedLeadEntitlements: [
          ...state.unfoldedLeadEntitlements,
          action.leadEntitlementId
        ]
      }
    }
    case 'TOGGLE_LEAD_ENTITLEMENT':
      return {
        ...state,
        unfoldedLeadEntitlements: some(
          state.unfoldedLeadEntitlements,
          element => element === action.leadEntitlementId
        )
          ? [
              ...state.unfoldedLeadEntitlements.filter(
                unfoldedElement => unfoldedElement !== action.leadEntitlementId
              )
            ]
          : [...state.unfoldedLeadEntitlements, action.leadEntitlementId]
      }
    default:
      return { ...state }
  }
}

export const nowEditingContact = contactId => ({
  type: 'EDITING_CONTACT',
  contactId
})

export const nowAddingContactToLeadEntitlement = leadEntitlementId => ({
  type: 'ADDING_CONTACT_TO_LEAD_ENTITLEMENT',
  leadEntitlementId
})

export const toggleLeadEntitlement = leadEntitlementId => ({
  type: 'TOGGLE_LEAD_ENTITLEMENT',
  leadEntitlementId
})

export const unfoldLeadEntitlement = leadEntitlementId => ({
  type: 'UNFOLD_LEAD_ENTITLEMENT',
  leadEntitlementId
})

export const setOrderKey = (attribute, direction = 'asc') => ({
  type: 'SORTING_ORDER_CHANGE',
  sorting: {
    key: attribute,
    direction
  }
})
