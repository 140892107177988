import React, {Component} from 'react'
import some from 'lodash/some'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Tooltip, TooltipContainer, TooltipTarget} from 'is24-corecss'
import { IconS24InfoCircle24, IconS24CrossCircle24, IconS24CheckmarkCircle24 } from '@is24/cosma-ui-icons';
import {
  nowAddingContactToLeadEntitlement,
  nowEditingContact,
  toggleLeadEntitlement,
  unfoldLeadEntitlement
} from './../reducers/ui'
import {
  updateContact,
  deleteContact,
  addContact
} from './../reducers/contacts'
import './LeadEntitlement.css'
import Contact from './Contact'
import ContactForm from './ContactForm'

const MAX_CONTACTS = 5

class LeadEntitlement extends Component {
  constructor(props) {
    super(props)
    this.addContactHandler = this.addContactHandler.bind(this)
  }

  addContactHandler(contact, leadEntitlementId) {
    const {
      addContact,
      unfoldLeadEntitlement,
      leadEntitlement: { contacts }
    } = this.props
    addContact(contact, leadEntitlementId).then(
      () => !contacts.length && unfoldLeadEntitlement(leadEntitlementId)
    )
  }

  render() {
    const {
      leadEntitlement,
      addingToLeadEntitlement,
      editingContact,
      nowAddingContactToLeadEntitlement,
      nowEditingContact,
      updateContact,
      deleteContact
    } = this.props

    const folded =
      !some(
        this.props.unfoldedLeadEntitlements,
        unfoldedLE => leadEntitlement.id === unfoldedLE
      ) || !this.props.leadEntitlement.contacts.length
    const foldingIconClass = `fa fa-angle-${folded ? 'down' : 'up'} fa`
    const contactsAreFull = leadEntitlement.contacts.length >= MAX_CONTACTS
    const showAddContactFormButton =
      (!folded || leadEntitlement.contacts.length === 0) &&
      addingToLeadEntitlement !== leadEntitlement.id &&
      !contactsAreFull
    return (
      <div key={leadEntitlement.id} className="grid-item one-whole ">
        <div className="grid grid-flex gutter-l border-top padding-bottom">
          <div className="grid-item one-whole desk-one-half">
            <div className="font-bold">Gebiet / Region</div>
            <div className="regionCell">{leadEntitlement.regionName}</div>
          </div>
          <div className="grid-item palm-one-whole lap-one-third desk-three-tenths">
            <div className="font-bold">Vertragsart</div>
            {leadEntitlement.type}
          </div>
          <div className="grid-item palm-one-half lap-one-third desk-one-tenth">
            <div className="font-bold">Startdatum</div>
            {leadEntitlement.startDate}
          </div>
          <div className="grid-item palm-one-half lap-one-third desk-one-tenth">
            <div className="font-bold">Enddatum</div>
            {leadEntitlement.endDate}
          </div>
          <div className="grid-item palm-one-half emails font-lightgray">
            <div className='grid grid-flex grid-align-center grid-item'>
              {
                leadEntitlement.deliverLeadsByEmail
              ? <div className='grid grid-flex grid-align-center grid-item'>
                  <IconS24CheckmarkCircle24 width={25} height={25} className='margin-right-xs' />
                    <span>Leads werden per Email zugestellt</span>
                </div>
              : <div className='grid grid-flex grid-align-center grid-item'>
                  <IconS24CrossCircle24 width={25} height={25} className='margin-right-xs' />
                  <span>Lead Versand per Email ist deaktiviert</span>
                </div>
              }
              <TooltipContainer className='grid-item'>
                <TooltipTarget className='font-lightgray font-l align-center'>
                  <IconS24InfoCircle24 color='#757575' width={20} height={20} className='margin-left-xs margin-top-xs' />
                </TooltipTarget>
                <Tooltip bodyPosition='bottom' arrowPosition='left'>
                  <div className='font-white font-xs' style={{ height: 70, width: 180 }}>
                    Bitte wenden Sie sich an unseren Vertrieb, wenn die diese Einstellung ändern wollen
                  </div>
                </Tooltip>
              </TooltipContainer>
            </div>
          </div>
          {leadEntitlement.deliverLeadsByEmail && (
            <div className="grid-item one-whole emails">
              <div className="font-bold">E-Mail-Adressen</div>
              <div className="padding-top">
                {leadEntitlement.contacts.length > 0 && (
                  <div
                    className={
                      !folded || addingToLeadEntitlement === leadEntitlement.id
                        ? 'padding-bottom'
                        : ''
                    }
                  >
                    <a
                      onClick={() =>
                        this.props.toggleLeadEntitlement(leadEntitlement.id)
                      }
                    >
                      {leadEntitlement.contacts.length} E-Mail-Adressen hinterlegt{' '}
                      <i className={foldingIconClass}/>
                    </a>
                  </div>
                )}

                <div
                  className={
                    !folded || addingToLeadEntitlement === leadEntitlement.id
                      ? 'emails-box background padding-l'
                      : ''
                  }
                >
                  {!folded && (
                    <div className="margin-bottom-l">
                      {leadEntitlement.contacts.map((contact, index) => {
                        if (editingContact !== contact.id) {
                          return (
                            <Contact
                              key={index}
                              onStartEdit={() => nowEditingContact(contact.id)}
                              onDelete={() => deleteContact(contact.id)}
                              {...contact}
                            />
                          )
                        } else {
                          return (
                            <ContactForm
                              key={index}
                              allContactsInLeadEntitlement={
                                leadEntitlement.contacts
                              }
                              onCancel={() => nowEditingContact(-1)}
                              onSubmit={contact => updateContact(contact)}
                              {...contact}
                            />
                          )
                        }
                      })}
                      {contactsAreFull && (
                        <div className="font-lightgray font-s">
                          Sie haben bereits {MAX_CONTACTS} Kontakte für diese
                          Region hinterlegt.
                        </div>
                      )}
                    </div>
                  )}

                  {addingToLeadEntitlement === leadEntitlement.id && (
                    <ContactForm
                      adding
                      allContactsInLeadEntitlement={leadEntitlement.contacts}
                      leadEntitlementId={leadEntitlement.id}
                      onSubmit={(contact, leadEntitlementId) =>
                        this.addContactHandler(contact, leadEntitlementId)
                      }
                      onCancel={() => nowAddingContactToLeadEntitlement(-1)}
                    />
                  )}

                  {showAddContactFormButton && (
                    <button
                      className="link-text padding-left-none"
                      onClick={() =>
                        nowAddingContactToLeadEntitlement(leadEntitlement.id)
                      }
                    >
                      <i className="fa fa-plus-square-o"/> E-Mail hinzufügen
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

LeadEntitlement.propTypes = {
  leadEntitlement: PropTypes.object,
  editingContact: PropTypes.number,
  addingToLeadEntitlement: PropTypes.number,
  nowEditingContact: PropTypes.func,
  nowAddingContactToLeadEntitlement: PropTypes.func,
  updateContact: PropTypes.func,
  addContact: PropTypes.func,
  deleteContact: PropTypes.func,
  unfoldLeadEntitlement: PropTypes.func,
  unfoldedLeadEntitlements: PropTypes.array
}

const mapStateToProps = ({ui}) => ({
  addingToLeadEntitlement: ui.addingToLeadEntitlement,
  editingContact: ui.editingContact,
  unfoldedLeadEntitlements: ui.unfoldedLeadEntitlements
})

export default connect(mapStateToProps, {
  nowEditingContact,
  deleteContact,
  updateContact,
  toggleLeadEntitlement,
  unfoldLeadEntitlement,
  addContact,
  nowAddingContactToLeadEntitlement
})(LeadEntitlement)
