export const getToken = () => window.SCOUT_TOKEN || null

export const setToken = token => {
  window.SCOUT_TOKEN = token
}

export const removeToken = () => {
  window.SCOUT_TOKEN = null
}

export const hasToken = () => getToken() !== null
