import { oauthProtectedRequest } from '../api/fetch'
import { nowEditingContact, nowAddingContactToLeadEntitlement } from './ui'
import {
  errorOccurredDuringDeleteContact,
  errorOccurredDuringAddContact,
  errorOccurredDuringUpdateContact
} from './errors'
import reject from 'lodash/reject'

const updateContactInState = ({ leadEntitlements }, contact) => {
  return leadEntitlements.map(leadEntitlement => {
    return {
      ...leadEntitlement,
      contacts: leadEntitlement.contacts.map(
        leadContact => (leadContact.id === contact.id ? contact : leadContact)
      )
    }
  })
}

const deleteContactInState = ({ leadEntitlements }, contactId) => {
  return leadEntitlements.map(leadEntitlement => {
    return {
      ...leadEntitlement,
      contacts: reject(leadEntitlement.contacts, { id: contactId })
    }
  })
}

const addContactInState = (
  { leadEntitlements },
  contact,
  leadEntitlementId
) => {
  return leadEntitlements.map(leadEntitlement => {
    return {
      ...leadEntitlement,
      contacts: leadEntitlement.id === leadEntitlementId
        ? [...leadEntitlement.contacts, contact]
        : [...leadEntitlement.contacts]
    }
  })
}

export const contacts = (state, action) => {
  switch (action.type) {
    case 'CONTACT_UPDATED':
      return {
        ...state,
        leadEntitlements: updateContactInState(state, action.contact)
      }
    case 'CONTACT_ADDED':
      return {
        ...state,
        leadEntitlements: addContactInState(
          state,
          action.contact,
          action.leadEntitlementId
        )
      }
    case 'CONTACT_DELETED':
      return {
        ...state,
        leadEntitlements: deleteContactInState(state, action.contactId)
      }
    default:
      return { ...state }
  }
}

const contactUpdated = contact => ({
  type: 'CONTACT_UPDATED',
  contact
})

const contactAdded = (contact, leadEntitlementId) => ({
  type: 'CONTACT_ADDED',
  contact,
  leadEntitlementId
})

const contactDeleted = contactId => ({
  type: 'CONTACT_DELETED',
  contactId
})

export const deleteContact = contactId => dispatch => {
  return oauthProtectedRequest(
    `/contact/${contactId}`,
    {
      method: 'DELETE'
    },
    errorOccurredDuringDeleteContact,
    dispatch
  ).then(() => dispatch(contactDeleted(contactId)))
}

export const updateContact = contact => dispatch =>
  oauthProtectedRequest(
    `/contact/${contact.id}`,
    {
      body: JSON.stringify(contact),
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      }
    },
    errorOccurredDuringUpdateContact,
    dispatch
  ).then(response => {
    if (response) {
      dispatch(nowEditingContact(-1))
      dispatch(contactUpdated(contact))
    }
  })

export const addContact = (contact, leadEntitlementId) => dispatch =>
  oauthProtectedRequest(
    `/leadentitlement/${leadEntitlementId}/contact`,
    {
      body: JSON.stringify(contact),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    },
    errorOccurredDuringAddContact,
    dispatch
  ).then(addedContact => {
    if (addedContact) {
      dispatch(contactAdded(addedContact, leadEntitlementId))
      dispatch(nowAddingContactToLeadEntitlement(-1))
      return Promise.resolve()
    }
  })
