import fetch from 'isomorphic-fetch'
import config from '../config'
import { getToken } from '../oauth/util/token'
import { authorize, logout } from '../oauth/lib'
import { cleanErrors } from '../reducers/errors'

const checkStatusCodeAction = (response, errorFetching, dispatch) => {
  if (response.status === 401) {
    dispatch(logout())
    dispatch(authorize())
    return null
  }
  if (response.status >= 400) {
    dispatch(errorFetching())
    return null
  }
  dispatch(cleanErrors())
  return response
}

const responseHasJsonBody = (response) => {
  const contentType = response.headers.get('content-type')
  return contentType && contentType.indexOf('application/json') !== -1
}

export const oauthProtectedRequest = (
  endpoint,
  options,
  errorFetching,
  dispatch
) => {
  const authHeader = { Authorization: 'BEARER ' + getToken() }
  return fetch(config.routing.rleApiURL + endpoint, {
    ...options,
    headers: { ...authHeader, ...options.headers },
  })
    .then((response) =>
      checkStatusCodeAction(response, errorFetching, dispatch)
    )
    .then((response) => {
      if (response && responseHasJsonBody(response)) {
        return response.json()
      }
    })
    .catch((err) => {
      dispatch(errorFetching(err))
      return null
    })
}
