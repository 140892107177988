import { leadEntitlements } from './leadEntitlements'
import { ui } from './ui'
import { errors } from './errors.js'
import { combineReducers } from 'redux'
import auth from './auth'

const reducer = combineReducers({
  leadEntitlements,
  ui,
  auth,
  errors
})

export default reducer
