import React from 'react'

export interface Contact {
  emailAddress: string
  salutation: string
  lastName: string
  onStartEdit: any
  onDelete: any
}

const Contact = ({ emailAddress, salutation, lastName, onStartEdit, onDelete }: Contact) => (
  <div key={emailAddress} className='margin-bottom contact grid grid-flex grid-fill-rows'>
    <div className='grid-item one-half'>
      <div className='font-ellipsis'>
        <span className='lastname'>
          {salutation === 'MALE' ? 'Herr' : 'Frau'} {lastName}
        </span>
        <span className='palm-hide margin-right-xs'>,</span>
        <span className='email'>
          <b>{emailAddress}</b>
        </span>
      </div>
    </div>
    <div className='grid-item grid-item-fixed-width controls'>
      <button onClick={onStartEdit} title='Bearbeiten' className='button padding-xs margin-left-s'>
        <i className='is24-icon-edit' />
      </button>
      <button onClick={onDelete} title='Löschen' className='button padding-xs margin-left-xs'>
        <i className='is24-icon-trash' />
      </button>
    </div>
  </div>
)

export default Contact
