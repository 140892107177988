import { oauthProtectedRequest } from '../api/fetch'
import { contacts } from './contacts'
import { errorOccurredDuringLeadEntitlementFetch } from './errors'
import orderBy from 'lodash/orderBy'

const initialState = {
  leadEntitlements: [],
  isLoadingLeadEntitlements: false
}

export const leadEntitlements = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_LEAD_ENTITLEMENTS':
      return {
        ...state,
        isLoadingLeadEntitlements: true
      }
    case 'LEAD_ENTITLEMENTS_RECEIVED':
      return {
        ...state,
        leadEntitlements: action.leadEntitlements,
        isLoadingLeadEntitlements: false
      }
    default:
      return contacts({ ...state }, action)
  }
}

export const getSorterLeadEntitlements = state =>
  orderBy(
    state.leadEntitlements.leadEntitlements,
    [state.ui.sorting.key],
    [state.ui.sorting.direction]
  )

const leadEntitlementsReceived = leadEntitlements => ({
  type: 'LEAD_ENTITLEMENTS_RECEIVED',
  leadEntitlements
})

const requestingLeadEntitlements = () => ({
  type: 'REQUEST_LEAD_ENTITLEMENTS'
})

export const loadLeadEntitlements = () => dispatch => {
  dispatch(requestingLeadEntitlements())
  return oauthProtectedRequest(
    '/leadentitlements',
    {},
    errorOccurredDuringLeadEntitlementFetch,
    dispatch
  ).then(leadEntitlements => {
    dispatch(leadEntitlementsReceived(leadEntitlements || []))
  })
}
