export default {
  routing: {
    rleApiURL: 'https://realtor-lead-engine-webapp-tuv.maklersuchmaschine.sandbox-immobilienscout24.de/api',
    rleApiPublicURL: 'https://realtor-lead-engine-webapp-tuv.maklersuchmaschine.sandbox-immobilienscout24.de/public',
  },
  header: {
    baseUrl: 'https://content.header.immobilienscout24.de/main',
  },
  oauth: {
    url: 'https://publicauth.sandbox-immobilienscout24.de/oauth/authorize',
    client: 'realtor-lead-engine-scoutmanager',
    redirect: 'https://manager.maklersuchmaschine.sandbox-immobilienscout24.de/',
  },
  reCaptchaSiteKey: '6LdhitgUAAAAAL1sYzDupHx1VnvRZI9pC-BPBGaE',
}
