export default {
  routing: {
    rleApiURL: 'http://localhost:8080/api',
    rleApiPublicURL: 'http://localhost:8080/public',
  },
  header: {
    baseUrl: 'https://content.header.immobilienscout24.de/main',
  },
  oauth: {
    url: 'http://publicauth.sandbox-immobilienscout24.de/oauth/authorize',
    client: 'realtor-lead-engine-scoutmanager',
    redirect: 'http://localhost:3000/',
  },
  reCaptchaSiteKey: '6LdU4dgUAAAAALDdT7nZ8XUtA7oy7Wrm4RhScgKr',
}
