import React from 'react'

const SortingCaret = ({ sortingKey, sorting }) => {
  if (sortingKey === sorting.key) {
    return (
      <i className={`fa fa-sort-${sorting.direction}`} aria-hidden="true" />
    )
  } else {
    return <i className="fa fa-sort" aria-hidden="true" />
  }
}

export default SortingCaret
