export default {
  routing: {
    rleApiURL: 'https://realtor-lead-engine-webapp-pro.maklersuchmaschine.immobilienscout24.de/api',
    rleApiPublicURL: 'https://realtor-lead-engine-webapp-pro.maklersuchmaschine.immobilienscout24.de/public',
  },
  header: {
    baseUrl: 'https://content.header.immobilienscout24.de/main',
  },
  oauth: {
    url: 'https://publicauth.immobilienscout24.de/oauth/authorize',
    client: 'realtor-lead-engine-scoutmanager',
    redirect: 'https://manager.maklersuchmaschine.immobilienscout24.de/',
  },
  reCaptchaSiteKey: '6LfViNgUAAAAAIlLuvHaVE1E7rvmVMcpc6E3pGnL',
}
