import React from 'react'

const errorMap = error => {
  let errorObject = {}
  if (error.leadEntitlements) {
    errorObject.header = 'Fehler beim Aufruf der Seite'
  }
  if (error.contact) {
    switch (error.contact.method) {
      case 'DELETE':
        errorObject.header = 'Fehler beim Löschen Ihrer Daten'
        break
      default:
        errorObject.header = 'Fehler beim Speichern Ihrer Daten'
    }
  }

  errorObject.body =
    'Bitte rufen Sie die Seite erneut auf oder versuchen Sie es zu einem späteren Zeitpunkt noch einmal.'
  return errorObject
}

const ErrorModal = ({ errors, onClose }) =>
  <div className="modal-overlay">
    <div
      className="modal-wrap border centered one-third"
      style={{ height: 'auto', border: 'none', borderRadius: 0 }}
    >
      <a data-x="" onClick={onClose} className="modal-close cross">
        <i className="fa fa-times float-right font-regular" />
      </a>
      <div className="modal-message padding-xl background-white">
        <div className="status-message status-message--short status-error">
          <h4>
            {errorMap(errors).header}
          </h4>
          <p>
            <br />
          </p>
        </div>
        <h2 data-title="dummy"> </h2>
        <div className="padding-bottom" data-text="">
          {errorMap(errors).body}
        </div>
        <div className="align-center">
          <button
            onClick={() => window.location.reload()}
            className="button-primary"
            data-close=""
          >
            Seite neu laden
          </button>
        </div>
      </div>
    </div>
  </div>

export default ErrorModal
