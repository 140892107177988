import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import querystring from 'query-string'
import Home from './containers/Home'
import Header from './components/Header'
import './App.css'

const App = () => {
  const [accessToken, setAccessToken] = useState(null)

  useEffect(() => {
    const { access_token } = querystring.parse(window.location.hash.slice(1))
    if (access_token) {
      setAccessToken(access_token)
    }
  }, [])

  const navigate = useNavigate()

  useEffect(() => {
    if (accessToken) {
      navigate('/', { replace: true })
    }
  }, [accessToken])

  return (
    <div className={'viewport'}>
      <Header />
      <div className={'page-wrapper border-none'}>
        <div className={'content-wrapper'}>
          <div className={'margin-bottom'}></div>
          <Home />
        </div>
      </div>
    </div>
  )
}

export default App
